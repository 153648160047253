<template>
  <div class="tree-el-outer">
    <el-tree
      class="tree-ele"
      :data="treeData"
      :props="defaultProps"
      node-key="id"
      highlight-current
      :default-expanded-keys="defaultExpandedKeys"
      @node-click="handleNodeClick"
    ></el-tree>
  </div>
</template>

<script>
  export default {
    name: 'clientTree',
    props: {
      treeData:{
        type: Array,
        default(){
          return [];
        }
      }
    },
    data() {
      return {
        defaultProps: {
          children: 'children',
          label: 'userName'
        }
      }
    },
    computed: {
      defaultExpandedKeys(){
        if(this.treeData.length){
          return [this.treeData[0].id];
        }else {
          return [0];
        }
      }
    },
    methods: {
      handleNodeClick(data) {
        if(data.id === this.treeData[0].id){
          this.$emit('node-click', data, {isRoot: true});
        }else {
          this.$emit('node-click', data,{isRoot: false});
        }
      }
    },
  }
</script>
<style lang="less">
  .tree-ele {
    min-width: 100%;
    display: inline-block;
  }
  .tree {
    overflow-x: auto;
  }
</style>
<style lang="less" scoped>
.tree-el-outer {
  margin-right: 20px;
  width: 200px;
  overflow-x: auto;
}
</style>
