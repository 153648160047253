<template>
  <div class="app-container performance summary-page performance-detail-container">
    <eHeader
      ref='header'
      :query="query"
      @clear="clearHandle"
      @toQuery="toQuery"
    />
    <div class="table-outer">
      <div
              v-if="treeData.length"
              class="client-tree">
        <div  class="result">搜索结果</div>
        <client-tree
          :tree-data="treeData"
          @node-click="handleNodeClick"
        ></client-tree>
      </div>
      <!--表格渲染-->
      <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column label="代理">
          <template slot-scope="scope">
            <div>{{ scope.row.custId }}</div>
            <div>{{ scope.row.custName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="managerName" label="销售姓名"/>
        <el-table-column label="上级信息">
          <template slot-scope="scope">
            <div>{{ scope.row.belongId || '-'}}</div>
            <div v-if="scope.row.belongName">{{ scope.row.belongName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="客户数">
          <template slot-scope="scope">
            <div>{{scope.row.personalCustomer}}</div>
          </template>
        </el-table-column>
        <el-table-column label="总盈亏">
          <template slot-scope="scope">
            <div class="profit-text" :class="{on: getGreen(scope.row)}">{{scope.row.personalProfit}}</div>
          </template>
        </el-table-column>
        <el-table-column label="客户总手数">
          <template slot-scope="scope">
            <div>{{scope.row.personalVolume}} 手 </div>
          </template>
        </el-table-column>
        <el-table-column prop="openTime" label="代理内转/返佣">
          <template slot-scope="scope">
            <div>{{scope.row.personalTransAmount}}/{{scope.row.personalCommission}}</div>
          </template>
        </el-table-column>
        <el-table-column label="总入金">
          <template slot-scope="scope">
            <div>{{scope.row.personalDepositAmount}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="storage" label="总出金">
          <template slot-scope="scope">
            <div>{{scope.row.personalOutAmount}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="personalNetIncome" label="净入金">
          <template slot-scope="scope">
            <div>{{scope.row.personalNetIncome}}</div>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <!--分页组件-->
    <public-pagination
            class="paging-outer"
            :class="{on: treeData.length > 0}"
            :total-page="total"
            :current-page="page"
      @pageChange="pageChange"
      @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
  import clientTree from '@/components/public/clientTree'
  import { queryReportTotal } from '@/api/ib/tManager'
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/stat/performanceDetail/header'
export default {
  name:'traderecord',
  components: {
    eHeader,
    clientTree,
  },
  mixins: [initDataComm],
  data() {
    return {
      loading: false,
      statisticsSelf:{
        sumAgent: '', //
        sumCommission: "",
        sumCustomer: '',
        sumDepositAmount: "",
        sumNetIncome: "",
        sumOutAmount: "",
        sumProfit: "",
        sumTransAmount: "",
        sumVolume: "",
      },
      treeData: [],
      export: 0,
      isTree: 1,
      treeCurrentNodeId: 0,
      isRoot: false,
    }
  },
  created() {
    this.$nextTick(() => {
      this.getTotol();
      this.init();
    })
  },
  updated(){
    this.$nextTick(() => {
      this.setSumProfitColor();
    })
  },
  methods: {
    parseTime,
    checkPermission,
    setSumProfitColor(){
      const s_table = document.getElementsByClassName('el-table__footer-wrapper')[0]
      const child_tr = s_table.getElementsByTagName('tr')[0]
      child_tr.childNodes.forEach((item,index) => {
        if(index == 4){
          let divEle = item.getElementsByClassName('cell')[0];
          if(divEle.innerText.indexOf('+') > -1){
            divEle.setAttribute('style', 'color: #11AC80');
          }else {
            divEle.setAttribute('style', 'color: #E74B3A');
          }
        }
      })
    },
    beforeInit() {
      this.url = '/crm/managerAchievement';
      this.params = {
        page: this.page,
        size: this.size,
        peopleType: 2,
        export: this.export,
        isTree: this.isTree,
      }
      if (this.query.name !=null && this.query.name!=='') {
        this.params.managerInfo = this.query.name
      };
      if (this.query.upName !=null && this.query.upName!=='') {
        this.params.custInfo = this.query.upName
      };
      if (this.$refs.header.startTime != null) {
        this.params['startTime'] = this.$refs.header.startTime;
      }
      if (this.$refs.header.endTime != null) {
        this.params['endTime'] = this.$refs.header.endTime;
      }
      if(this.isTree){
        if(this.query.custInfo){
          this.params.custInfo = this.query.custInfo;
          if(this.query.dataType){
            this.params.dataType = this.query.dataType;
          }else {
            this.params.dataType = 0;
          }
        }
      }else {
        if(this.treeCurrentNodeId !== 0){
          this.params.custInfo = this.treeCurrentNodeId;
        }else {
          this.params.custInfo = this.query.custInfo;
        }
        if(this.isRoot){
          this.params.dataType = this.query.dataType;
        }else {
          this.params.dataType = 0;
        }
      }
      return true
    },
    getTotol(){
      let params = {
        startTime: this.$refs.header.startTime,
        endTime: this.$refs.header.endTime,
        peopleType: 2,
        managerInfo: this.query.name,
        custInfo: this.query.custInfo,
        dataType: this.query.dataType,
      }
      if(this.query.custInfo){
        if(!this.query.dataType){
          params.dataType = 0;
        }
      }
      queryReportTotal(params).then((res) => {
        this.statisticsSelf = res.statistics[0];
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 1:
            sums[index] = '';
            break;
          case 2:
            sums[index] = ``;
            break;
          case 3:
            sums[index] = `${this.statisticsSelf.sumCustomer}`;
            break;
          case 4:
            sums[index] = `${this.statisticsSelf.sumProfit}`;
            break;
          case 5:
            sums[index] = `${this.statisticsSelf.sumVolume}手`;
            break;
          case 6:
            sums[index] = `${this.statisticsSelf.sumTransAmount}/${this.statisticsSelf.sumCommission}`;
            break;
          case 7:
            sums[index] = `${this.statisticsSelf.sumDepositAmount}`;
            break;
          case 8:
            sums[index] = `${this.statisticsSelf.sumOutAmount}`;
            break;
          case 9:
            sums[index] = `${this.statisticsSelf.sumNetIncome}`;
            break;
        }
      });

      return sums;
    },
    getGreen(row){
      if(row.personalProfit.indexOf('+') > -1){
        return true;
      }else {
        return false;
      }
    },
    clearHandle(){
      this.query = {
        name: '',
        custInfo: '',
        dataType: '',
      }
      this.toQuery();
      this.getTotol();
    },
    handleNodeClick(data, object) {
      this.isRoot = object.isRoot;
      this.isTree = 0;
      this.page = 0;
      this.treeCurrentNodeId = data.id;
      this.init();
      let params = {
        startTime: this.$refs.header.startTime,
        endTime: this.$refs.header.endTime,
        peopleType: 2,
        custInfo: this.treeCurrentNodeId,
        dataType: 0,
      }
      if(object.isRoot){
        params.dataType = this.query.dataType;
      }
      queryReportTotal(params).then((res) => {
        this.statisticsSelf = res.statistics[0];
      })
    },
    toQuery(){
      if(this.query.dataType === 1){
        this.isTree = 0;
      }else {
        this.isTree = 1;
      }
      this.page = 0;
      this.init();
      this.getTotol();
    },
    pageChange(e){
      this.isTree = 0;
      this.page = e - 1;
      this.init();
    }
  }
}
</script>

<style lang="less" scoped>
  .profit-text {
    color: #E74B3A;
  &.on {
     color: #11AC80;
   }
  }
  .result {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .performance-detail-container {
    position: relative;
    padding-bottom: 50px;
  }
  .paging-outer {
    position: absolute;
    left: 20px;
    bottom: 10px;
    &.on {
      left: 240px;
    }
  }
</style>
