<template>
  <div class="head-container performanceDetail-header">
    <!-- 搜索 -->
      <el-input
              v-model="query.custInfo"
              clearable
              placeholder="代理姓名/ID"
              style="width: 400px;"
              class="input-with-select"
              @keyup.enter.native="toQuery">
          <el-select
                  style="width: 180px;"
                  size="small"
                  slot="append"
                  class=""
                  v-model="query.dataType"
                  clearable
                  placeholder="层级"
          >
              <el-option
                      v-for="(item,index) in typeList"
                      :key="index"
                      :value="item.value"
                      :label="item.label"
              ></el-option>
          </el-select>
      </el-input>
    <el-input
      v-model="query.name"
      clearable
      placeholder="请输入销售姓名/ID"
      style="width: 180px;"
      class="filter-item"
      @keyup.enter.native="toQuery"/>


     <div class="filter-item">
       <!--接口必须传时间, 选择时间-->
       <span class="filter-item">平仓时间</span>
      <el-date-picker
        style="width: 150px;"
        v-model="startTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="endTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="结束时间">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>

  </div>
</template>

<script>
  import { init_date } from '@/utils/index'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  created(){
    this.initDate();
  },
  data() {
    return {
      startTime: '',
      endTime: '',
      typeList: [
        {
          label: '本人',
          value: 1,
        },
        {
          label: '所有下级',
          value: 2,
        },
        {
          label: '本人 + 所有下级',
          value: 0,
        },
      ]
    }
  },
  methods: {
    initDate(){
      let dateInfo = init_date();
      this.endTime = dateInfo.today;
      this.startTime = dateInfo.firstday;
    },
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear() {
      this.$emit('clear');
    },
  }
}
</script>
<style lang="less">
  .performanceDetail-header {
    .el-input-group__append {
      background-color: #ffffff;
    }
    .input-with-select {
        margin-right: 5px;
        vertical-align: top;
    }
  }
</style>
